@import "../../../../static/scss/variables";

.dot-container{
  display: inline-block;
}

 .dot-flashing-5 {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    // margin-left:8px;
    background-color: $blue;
    color: $blue;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -5px;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: $blue;
        color: $blue;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 5px;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: $blue;
        color: $blue;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
      }
      
  }
 .dot-flashing-10 {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $blue;
    color: $blue;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $blue;
        color: $blue;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $blue;
        color: $blue;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
      }
      
  }
  
  

  @keyframes dotFlashing {
    0% {
      background-color:$blue;
    }
    50%,
    100% {
      background-color: $blue-4;
    }
  }