@import "../../../static/scss/variables";
.custom-legend {
  display: flex;
  align-items: center;
  margin-right: 10px;

  .legend-box {
    padding: 0px 3px 0px 2px;
  }

  .custom-legend-block {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    // margin-right: 3px;
  }
  .custom-legend-label {
    font-size: 10px;
    font-weight: 600;
    color: $darkgray-2;
  }
}
