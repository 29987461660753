@import '../../../../static/scss/variables';
@media only screen and (max-width: 320px) {
  .floating-btn-apply-filter {
    width: 85% !important;
  }
  .share-menu-button {
    margin-left: 8px;
  }
  .filter-menu-button {
    margin-left: -4px;
  }
  .analytics-card {
    height: 90px;
    .analytics-card-header {
      margin-bottom: 2px !important;
    }
    .count-percentage {
      line-height: 1 !important;
      .value {
        font-size: 15px !important;
        display: block !important;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .floating-btn-apply-filter {
    width: 85% !important;
  }
}
@media only screen and (max-width: 550px) {
  .floating-btn-apply-filter {
    width: 85% !important;
  }
}
.filter-wrapper {
  background: white;
  padding: 0px 20px 15px 20px;
  .filter-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 0px;
    .filter-heading {
      color: $black;
      font-family: Lato;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
    }
    .filter-clear {
      color: $darkgray-1;
      font-family: Lato;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .filter-body {
  }
}
// ========================== header
.filters-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background: $lightgray-2;
  .filter-heading {
    color: $black;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .filter-clear {
    color: $darkgray-1;
    font-family: Lato;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    align-items: center;
  }
}

// ==============
.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
}
.features-hours {
  display: flex;
  flex-direction: column;
}
.devices-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;

  button {
    justify-content: center;
    // width: 100%;
  }
}
.custom-btn {
  width: 30%;
  display: inline-flex !important;
  margin-right: 10px;
  margin-bottom: 10px;
  justify-content: center;
  max-width: 150px;
}
.userTypes-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;

  button {
    justify-content: center;
    // width: 100%;
  }
}

// ===============================

.items-container {
  display: grid;
  grid-gap: 10px;
  .item-container {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }
}

.floating-btn-apply-filter {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  z-index: 1;
  width: 25%;
  height: 40px;
  // opacity: 0.9;
  padding: 0;
}

.filters-chips {
  border: 1px solid $lightgray-1;
  border-radius: 8px;
  .tags-container {
    padding: 5px;
    .MuiChip-root {
      margin-right: 3px;
      margin-top: 5px;
      background-color: $white;
      color: $blue;
      border: 1px solid #c1c1e2;
      font-weight: 400;
      height: 28px;
    }
    svg {
      color: #c1c1e2;
      width: 18px;
      height: 18px;
    }
  }
  .MuiDivider-middle {
    margin-left: 0px;
    margin-right: 0px;
  }
  .filter-title {
    padding: 10px 10px;
    font-size: 14px;
  }
}
.lightGrey {
  color: $darkgray-2;
}
// filter chips background color classes
.chips-background {
  background: #ececfd !important;
  color: #3b3bba !important;
  font-weight: 700 !important;
  font-family: 'Lato', sans-serif !important;
  svg {
    color: #3b3bba !important;
  }
}

.MuiAutocomplete-popper {
  margin-bottom: 10px !important;
}
.MuiAutocomplete-paper {
  border: 2px solid lightgray !important;
}

.filter-panel-tooltip {
  .tooltip-inner {
    max-width: inherit !important;
  }
}
.non-filter-graph-filter-panel-heading {
  color: $black;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
