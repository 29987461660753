@import "variables";
@import "functions";

.form-control{
  color: $darkgray-4;
  &:focus, &:active{
    color: $darkgray-4;
  }

  & + .form-label{
    color: $darkgray-5;
  }
}
