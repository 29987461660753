.col-chat-wrap {
    border-radius: 30px;
}

.chat-list-wrapper {
    width: 360px;
    position: relative;
    max-width: 100%;
}

.all-chat-section {
    &.card-rounded {
        padding-left: 0;
    }
}
