@import "variables";
@import "functions";

.timeZone{
    position: relative;
    & select.select{
        padding-right: 20px;
    }
    & .form-control.select.select + .select-arrow {
        top: 28px;
    }
    & .form-control.select.select + .select-arrow.toggled {
        top: 28px;
        right: 0px;
    }
    & .form-control.select.select::-webkit-scrollbar{
        background-color: $lightgray-1;
        width: 6px;
        border-radius: 2px;
      }
    & .form-control.select.select::-webkit-scrollbar-thumb{
    background-color: $darkgray-2;
    border-radius: 2px;
    }
}

//scss for DisplayKey component
.displayKey{
    font-size: 13px;
    color: $darkgray-2;
}