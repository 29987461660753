@import '../../static/scss/variables';

.container-header-existing-portal {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background: $warning-4;
  border: 1px solid $darkgray-6;
  border-bottom: none;
  align-items: center;

  .msg {
    color: $warning-2;
    font-weight: 700;
  }

  .btn-container {
    padding: 2px 10px;
    color: black;

    .existing-btn-text {
      font-size: 15px;
      font-weight: 700;
    }

    .existing-btn-arrow {
      height: 24px;
      width: 24px;
      transform-box: fill-box;
    }
  }
}
