@import 'variables';
@import 'functions';

.table-sticky {
  border-radius: 24px;
  box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.08);
  & .thead {
    border-style: solid;
    border-color: $lightgray-1;
    border-width: 1px 1px 0px 1px;
    border-radius: 24px 24px 0 0;
    padding: 0 3px 5px 5px;
  }
  & .tbody {
    border: 1px solid $lightgray-1;
    border-radius: 0 0 24px 24px;
    padding: 3px 3px 15px 5px;
    background: #fff;
  }
  & tbody {
    height: 460px;
    overflow-y: auto;
    width: 100%;
    background-color: #fff;
    &::-webkit-scrollbar {
      background-color: $lightgray-1;
      width: 6px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $darkgray-2;
      border-radius: 2px;
    }
  }
  & thead,
  & tbody,
  & td,
  & th {
    display: block;
  }
  & th label {
    color: $darkgray-1;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    width: 100%;
  }
  & td,
  & th {
    border: none !important;
    width: 100%;
    &:nth-child(1),
    &:nth-child(1) {
      width: 40px;
    }
  }
  & th {
    padding-right: 0;
  }
  & td {
    font-size: 13px;
    color: $darkgray-2;
  }
  & tr {
    display: flex;
  }
  & tbody tr {
    align-items: center;
    border-top: 1px solid $lightgray-2;
  }
  & button {
    font-size: 11px;
    &.btn-success-3 {
      color: $success !important;
      border-color: $success-3 !important;
      border-radius: 16px !important;
    }
    &.btn-success-4 {
      color: $success !important;
      border-color: $success-3 !important;
      border-radius: 16px !important;
    }
    &.btn-danger-4 {
      color: $danger !important;
      border-color: $danger-3 !important;
      border-radius: 16px !important;
    }
    &.btn-info-1 {
      color: $blue-1 !important;
      border-color: $blue-1 !important;
      border-radius: 16px !important;
    }
    &.btn-warning-3 {
      color: $warning-2 !important;
      border-color: $warning-2 !important;
      border-radius: 16px !important;
    }
    &.btn-darkgray-7 {
      color: $darkgray-2 !important;
      border-color: $darkgray-2 !important;
      border-radius: 16px !important;
    }
    &.btn-success-7 {
      color: $success !important;
      border-color: $success !important;
      border-radius: 16px !important;
      box-shadow: none !important;
    }
  }
}

.grid-filter {
  & input.form-control {
    height: 24px;
    font-size: 0.75rem;
    border-color: $lightgray-1;
    border-left: 0;
    padding-left: 5px;
    &:focus {
      border-color: $lightgray-1;
      box-shadow: none;
    }
    &::placeholder {
      color: $darkgray-3;
    }
  }
  & .input-group-text {
    height: 24px;
    background-color: $white;
    color: $darkgray-3;
    padding-right: 0;
    padding-left: 5px;
  }
}
.badge-align {
  position: relative;
  top: -5px;
  min-width: 24px;
  height: 24px;
  font-size: 13px !important;
}

.filter-icon svg {
  position: absolute;
  right: 0;
  cursor: pointer;
  &.filter-icon-up {
    top: 0;
  }
  &.filter-icon-down {
    top: 10px;
  }
}

.InovuaReactDataGrid {
  box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.08) !important;
  overflow: hidden !important;
  min-height: calc(100vh - 100px);
  border-radius: 10px;
  .InovuaReactDataGrid__header {
    background-color: #f4f6f8 !important;
  }
}
.report-section-wrapper {
  padding: 0px;
  .InovuaReactDataGrid {
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}

button.btn-status {
  font-size: 11px;
  cursor: auto !important;
  padding: 2px 5px 2px 2px;
  &.btn-success-3 {
    color: $success !important;
    border-color: $success-5 !important;
    border-radius: 16px !important;
  }
  &.btn-success-4 {
    color: $success !important;
    border-color: $success-3 !important;
    border-radius: 16px !important;
  }
  &.btn-danger-4 {
    color: $danger !important;
    border-color: $danger-3 !important;
    border-radius: 16px !important;
  }
  &.btn-info-1 {
    color: $blue-1 !important;
    border-color: $blue-1 !important;
    border-radius: 16px !important;
  }
  &.btn-warning-3 {
    color: $warning-2 !important;
    border-color: $warning-2 !important;
    border-radius: 16px !important;
  }
  &.btn-darkgray-7 {
    color: $darkgray-2 !important;
    border-color: $darkgray-2 !important;
    border-radius: 16px !important;
  }
  &.btn-success-7 {
    color: $success !important;
    border-color: $success !important;
    border-radius: 16px !important;
    box-shadow: none !important;
  }
}
.InovuaReactDataGrid__sort-icon--hidden {
  visibility: visible !important;
}

.InovuaReactDataGrid__column-header--align-start.InovuaReactDataGrid__column-header--direction-ltr
  > .InovuaReactDataGrid__column-header__menu-tool--show-on-hover {
  opacity: 0 !important;
  pointer-events: all !important;
}

// .InovuaReactDataGrid--theme-default-light
//   .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
//   border-left: 0px solid $darkgray-6 !important;
//   padding: 10px 0px;
// }

.copany-data-grid-wrapper {
  .InovuaReactDataGrid__column-header__resize-wrapper:nth-child(4),
  .InovuaReactDataGrid__column-header__resize-wrapper:nth-child(5),
  .InovuaReactDataGrid__column-header__resize-wrapper:nth-child(6) {
    .InovuaReactDataGrid__column-header__filter-settings {
      display: none !important;
    }
  }
}

.inovua-react-toolkit-load-mask__background-layer {
  //opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.InovuaReactDataGrid__loading-text {
  color: #ffffff !important;
  font-weight: 700;
}

div.InovuaReactDataGrid--theme-default-light {
  & .InovuaReactDataGrid__column-header--sortable {
    pointer-events: none;
  }
  & .InovuaReactDataGrid__column-header__filter-wrapper {
    background-color: $lightgray-2;
    border-top: 0px solid $darkgray-6;
  }
  & .InovuaReactDataGrid__column-header__resize-wrapper--has-no-group {
    border-left: 0px solid $darkgray-6;
    padding: 10px 0px;
  }
  &
    .InovuaReactDataGrid__column-header__content.InovuaReactDataGrid__box--ellipsis {
    font-weight: 600;
  }
}

div.inovua-react-scroll-container__wrapper {
  & .copy-button-wrapper {
    font-size: 13px;
    color: #637381;
    font-weight: 500;
  }
}

.inovua-react-toolkit-text-input,
.inovua-react-toolkit-combo-box,
.inovua-react-toolkit-date-input {
  border-radius: 6px !important;
}

.inovua-react-toolkit-menu {
  .inovua-react-toolkit-menu__table {
    tr {
      td.inovua-react-toolkit-menu__cell--radio {
        display: none !important;
      }
      &:nth-last-child(-n + 6) {
        display: none !important;
      }
    }
  }
}
tr.inovua-react-toolkit-menu__row.inovua-react-toolkit-menu__row--checked {
  cursor: auto;
  pointer-events: none;
  td {
    color: $lightgray-4 !important;
  }
}

.hide-filter-button + div + div {
  .InovuaReactDataGrid__column-header__filter-settings {
    display: none !important;
  }
}

.InovuaReactDataGrid__sort-icon-wrapper {
  position: absolute;
  right: 0px;
  top: 13px;

  & svg {
    fill: #6b7180 !important;
  }

  & .InovuaReactDataGrid__sort-icon--active {
    fill: #454f5b !important;
  }
}

.disabled-field + div + div {
  pointer-events: none;
  div {
    background: #f2f2f2 !important;
  }
}

.blux-text {
  .InovuaReactDataGrid__cell__content {
    opacity: 0.3;
  }
}

.inovua-react-scroll-container__view {
  width: 100% !important;
} 

.inovua-react-pagination-toolbar__spacer {
  display: none !important;
}

.inovua-react-toolkit-arrow-scroller__inner-wrapper, .inovua-react-toolkit-toolbar__inner-wrapper {
  justify-content: flex-end !important;
  margin-right: 20px;
}

.inovua-react-pagination-toolbar__icon--named--REFRESH {
  display: none !important;
}