$blue: #2d2d95;
$black: #454F5B;
$light-blue: #a5a5ea;
$primary: $blue;
$blue-1: #1c1c78;
$blue-2: #3b3bba;
$blue-3: #c1c1fa;
$blue-4: #ececfd;
$info: $light-blue;

$dark: #212b36;
$darkgray: #212b36;
$secondary: $darkgray;
$darkgray-1: #454f5b;
$darkgray-2: #637381;
$darkgray-3: #919eab;
$darkgray-4: #2d3c52;
$darkgray-5: #5a6672;
$darkgray-6: #e4e3e2;
$darkgray-7: #cbdbe8;
$darkgray-8: #dddddd;

$lightgray: #c4cdd5;
$lightgray-1: #dfe3e8;
$lightgray-2: #f4f6f8;
$lightgray-3: #f9fafb;
$lightgray-4: #abb3bc;
$lightgray-5: #edeff2;

$success: #108043;
$success-1: #173630;
$success-2: #50b83c;
$success-3: #bbe5b3;
$success-4: #e3f1df;
$success-5: #8ab782;
$success-6: #b9e7b9;
$success-7: #b9e7b9;

$warning: #f49342;
$warning-1: #4a1504;
$warning-2: #c05717;
$warning-3: #ffc58b;
$warning-4: #fcebdb;
$warning-5:#ffebeb;
$warning-6:#704500;

$danger: #bf0711;
$danger-1: #330101;
$danger-1: #bf0711;
$danger-2: #de3618;
$danger-3: #fead9a;
$danger-4: #fbeae5;

$white: #fff;

$unit: 8px;

$colors: (blue: $blue,
  primary: $primary,
  blue-1: $blue-1,
  blue-2: $blue-2,
  blue-3: $blue-3,
  blue-4: $blue-4,
  darkgray: $darkgray,
  secondary: $secondary,
  darkgray-1: $darkgray-1,
  darkgray-2: $darkgray-2,
  darkgray-3: $darkgray-3,
  darkgray-7: $darkgray-7,
  lightgray: $lightgray,
  lightgray-1: $lightgray-1,
  lightgray-2: $lightgray-2,
  lightgray-3: $lightgray-3,
  success: $success,
  success-1: $success-1,
  success-2: $success-2,
  success-3: $success-3,
  success-4: $success-4,
  success-5: $success-5,
  warning: $warning,
  warning-1: $warning-1,
  warning-2: $warning-2,
  warning-3: $warning-3,
  warning-4: $warning-4,
  danger: $danger,
  danger-1: $danger-1,
  danger-2: $danger-2,
  danger-3: $danger-3,
  danger-4: $danger-4,
  white: $white,
  info-1: $light-blue);

$theme-colors: () !default;
$theme-colors: map-merge(('blue': map-get($colors, blue),
    'darkgray': map-get($colors, darkgray)),
  $theme-colors);

@each $colorKey,
$colorName in $colors {
  .text-#{$colorKey} {
    color: $colorName !important;
  }

  .bg-#{$colorKey},
  .btn-#{$colorKey} {
    background-color: $colorName !important;
  }

  .btn-outline-#{$colorKey} {
    border-color: $colorName !important;
    color: $colorName !important;
    box-shadow: 0 4px 8px 0 rgba($colorName, 0);
    background-color: rgba(255,255,255,0) !important;
    
    &:hover,
    &:focus {
      background-color: $colorName !important;
      color: #FFF !important;
    }
  }

  .btn-#{$colorKey} {
    color: #fff !important;
    box-shadow: 0 4px 8px 0 rgba($colorName, 0.24);

    &:hover,
    &:focus {
      background: darken($colorName, 4%) !important;
      box-shadow: 0 6px 12px 0 rgba($colorName, 0.24);
      // box-shadow: 0 0 0 0.2rem rgba($colorName, 0.5) !important;
    }
  }
}

.btn-lightgray-3 {
  border: 1px solid $lightgray;
  color: $darkgray-2 !important;
}

@mixin badgeVariant($bg, $border, $text) {
  background-color: $bg;
  border-color: $border;
  color: $text;
}

.badge {
  border: 1px solid;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 4px 2px 2px;
  background-color: $blue-2;

  &-text {
    margin-left: 2px;
  }

  svg,
  img {
    width: 12px;
    height: 12px;
  }

  &-primary {
    // conflict styles on other badges e.g: in companies heading badge 
    // @include badgeVariant($blue-4, $blue-2, $blue);
  }

  &-secondary {
    @include badgeVariant($lightgray-4, $lightgray-3, $lightgray);
  }

  &-success {
    @include badgeVariant($success-4, $success-2, $success);
  }

  &-danger {
    @include badgeVariant($danger-4, $danger-2, $danger);
  }

  &-warning {
    @include badgeVariant($warning-5, $warning-6, $warning-6);
  }

  &-info {}

  &-light {}

  &-dark {}
}

$fontSizer: (10,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  34,
  35,
  36,
  38,
  40,
  42,
  44,
  45,
  50,
  60,
  70,
  80,
  200,
  240,
  250);

$fontWeight: (100, 300, 400, 500, 600, 700, 900);

@each $fontsize in $fontSizer {
  .fz-#{$fontsize} {
    font-size: #{$fontsize}px !important;
  }

  .rounded-#{$fontsize} {
    border-radius: #{$fontsize}px !important;
  }
}

@each $fontweight in $fontWeight {
  .fw-#{$fontweight} {
    font-weight: #{$fontweight} !important;
  }
}

$liftSizer: (0,
  1,
  2,
  3,
  4,
  5,
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  200,
  300,
  400,
  500,
  1000,
  2000,
  3000,
  4000,
  5000);

@each $lift in $liftSizer {
  .lift-#{$lift} {
    z-index: #{$lift} !important;
  }
}

$spacers: () !default;
$spacers: map-merge((1r: 0.25rem,
    2r: 0.5rem,
    3r: 0.75rem,
    4r: 1rem,
    5r: 1.25rem,
    6r: 1.5rem,
    7r: 1.75rem,
    8r: 2rem,
    9r: 2.25rem,
    10r: 2.5rem,
    11r: 2.75rem,
    12r: 3rem,
    4: 4px,
    8: 8px,
    12: 12px,
    16: 16px,
    20: 20px,
    24: 24px,
    32: 32px,
    36: 36px,
    40: 40px,
    48: 48px,
    56: 56px,
    64: 64px,
    72: 72px,
    80: 80px,
    88: 88px,
    96: 96px,
    104: 104px,
    112: 112px,
    120: 120px,
    136: 136px,
    152: 152px),
  $spacers);

.vertical-middle {
  vertical-align: middle;
}

.text-upper {
  text-transform: uppercase;
}

p:last-child {
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

@mixin transition($delay) {
  transition: all ease $delay;
}
