@import '../../../../static/scss/variables';

.chat-details-card{
  font-family:Lato,"Segoe UI Emoji", serif;
}

.user-type {
  position: absolute;
  bottom: -24px;
  left: 0;
  font-size: 6px;
  color: #fff;
  padding: 4px;
}

.BOT {
  background-color: $warning;
}

.SYSTEM {
  background-color: $info;
}

.PRECHAT {
  background-color: $dark;
}

.PRE-DEFINED {
  background-color: $secondary;
}

.POKE {
  background-color: $success;
}

.AGENT {
  background-color: $primary;
}

.radio-as-button-wrapper {
  .custom-control {
    &-label {
      text-align: center;
      display: block;
      background: $lightgray-2;
      color: $darkgray-2;
      padding: 0.25rem 0.125rem;
      border-radius: 0.25rem;
      transition: 0.3s ease all;
      cursor: pointer;
    }

    &-input {
      ~.custom-control-label {

        &:before,
        &:after {
          visibility: hidden;
        }
      }

      &:checked {
        ~.custom-control-label {
          background: $blue-4;
          color: $blue-2;
        }
      }
    }
  }
}

.chat-details-header {
  display: flex;

  .chat-header {
    &-visitor-name {
      color: $primary;
      font-weight: 700;
      margin-bottom: -0.5rem;
    }

    &-start-time {
      font-size: 0.75rem;
    }
  }

  &-left{
    .chat-back-arrow{
      padding: 0px 4px;
      background-color: red;
      .arrow-left-icon{
        font-size: 16px;
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
  }

  // align-items: center;
  // justify-content: center;
}

.user-type {
  position: absolute;
  bottom: -24px;
  left: 0;
  font-size: 6px !important;
  color: #fff;
  padding: 4px !important;
}

.BOT {
  background-color: $warning !important;
}

.SYSTEM {
  background-color: $info !important;
}

.PRECHAT {
  background-color: $dark !important;
}

.PRE-DEFINED {
  background-color: $secondary !important;
}

.POKE {
  background-color: $success !important;
}

.AGENT {
  background-color: $primary !important;
}

.font-color {
  color: $blue;
}

.font-color-disabled {
  color: $darkgray-3;
}

.chat_user_avatar {
  .user-avatar {
    width: 28px;
  }
}

.input-action-icon {
  &.emojis-wrap {
    width: 22px;
    margin-left: 12px;
  }

  .action-icon {
    svg {
      color: $darkgray-2 !important;
    }

    &.active {
      svg {
        color: $primary !important;
      }
    }
  }

  .submit-icon svg {
    // color: $blue !important;
  }
}

.chat_message_wrapper {
  &.chat_message_left {
    .copy-to-form {
      display: none;
    }
  }

  &.chat_message_right {
    .copy-to-form {
      position: absolute;
      right: 0.325rem;
      top: 0.7rem;
      opacity: 0;
      transition: opacity ease .3s;
      visibility: hidden;
      cursor: pointer;

      .dropdown-toggle {
        padding: 0;
        box-shadow: none;

        &:after {
          display: none;
        }
      }

      .dropdown-item {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
      }

      .dropdown-menu {
        padding: 0;
        min-width: 100px;
      }
    }

    &:hover .copy-to-form,
    .copy-to-form.opened {
      opacity: 1;
      visibility: visible;
    }
  }
}

.box-shadow-bottom {
  box-shadow: 0 1px 8px #b6b6b6bf;
}
