@import 'variables';

.collapsible-container ul {
    list-style: none;
    padding: 0;
}

@mixin collapsibleSetup {
    cursor: pointer;
    padding: .625rem 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.collapsible {

    &-container {
        .collapse {
            &-trigger {
                font-weight: 700;
                @include collapsibleSetup;
                color: $darkgray-1;
                position: relative;
                white-space: nowrap;
                svg,
                img {
                    width: 1rem;
                    height: 1rem;
                    margin-right: .5rem;
                }

                .collapse-caret {
                    position: absolute;
                    right: 0;
                    top: calc(50% - 14px);
                    width: 28px;
                    height: 28px;
                    @include transition(.3s)
                }

                &.active {
                    background: #FFF;
                    color: $blue-2;
                    text-decoration: none;

                    .collapse-caret {
                        transform: rotate(180deg);
                    }
                }

                &:not(.active) {
                    svg {
                        fill: $darkgray-3;
                    }
                }
            }
        }
    }

    &-nav {
        a {
            @include collapsibleSetup;
            padding: .625rem 3.5rem;
            @include transition(.3s);
            text-decoration: none;
            color: $darkgray-2;
            white-space: nowrap;

            &:hover,
            &.active {
                color: $blue-2;
            }

            &.active {
                font-weight: 700;
            }
        }
    }

}
