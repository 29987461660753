@import "../../../../static/scss/variables";


.splash-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

 .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $blue;
    color: $blue;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $blue;
        color: $blue;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $blue;
        color: $blue;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
      }
      
  }
  
  

  @keyframes dotFlashing {
    0% {
      background-color:$blue;
    }
    50%,
    100% {
      background-color: $blue-4;
    }
  }