@import "variables";
@import "functions";

.navbar-nav .tab {

  nav {
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border-radius: 24px 0 0 0;
    border: none;
    a {
      display: inline-block;
      text-align: center;
      padding: 14px;
      text-decoration: none;
    }
  }

  &-ui {
    position: relative;
    background: $lightgray-2 !important;
    font-size: 15px;
    border: none;
    color: $darkgray-1 !important;
    span {
      display: flex;
      justify-content: space-between;
    }

    &.active,
    &:hover {
      border: none;
      color: $darkgray-1 !important;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 10px 10px 0 0;
      height: 6px;
      width: 100%;
      background-color: $white;
      border: none;
    }
    &:hover {
      color: $blue-2 !important;
    }
    &.active {
      color: $blue-2 !important;
      &:after {
        border-radius: 10px 10px 0 0;
        content: "";
        height: 6px;
        width: 100%;
        background-color: $blue-2;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      & .close-btn {
        color: $blue-2;
      }
    }
    &.close-btn {
      font-weight: 600;
      color: lightgray;
    }
    .tab-heading {
      font-family: Lato;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.uncontrolledTabs {
  background: #eaedf1;
  height: 54px;
  .nav-link {
    color: #637381;
    font-weight: bold;
    line-height: 37px;
    border: 0px;
    &.active {
      background-color: transparent;
      color: #3b3bba !important;
      position: relative;
      &::after {
        content: "";
        background: #3b3bba;
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: 0px;
      }
    }
  }
}
