@import '../../../static/scss/variables';

.lead-form-section {
  .call-connect-status {
    font-size: 13px;
    .call-status-badge {
      // color:#fff;
      padding: 3px 5px 3px 2px !important;
    }
    .badge-primary{
      background-color: $blue-4;
      border-color: $blue-2;
      color: $blue;
    }
  }

  .lead-form-footer{
    .lead-form-buttons{
      display: flex;
      justify-content: flex-end;
    }
    .submit-lead {
      font-weight: 600;
    }
  }
}
